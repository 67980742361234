<template>
  <button class="button" @click="openDrawer">
    <img :src="require('@/assets/svg/v2/portfolio/gear.svg')" alt="Gear icon" />
    {{ $t('portfolio.settings') }}
  </button>

  <CommonDrawer
    v-if="showDrawer"
    :title="$t('portfolio.settings')"
    @close="closeDrawer"
  >
    <DrawerSettingsContent @close="closeDrawer" />
  </CommonDrawer>
</template>

<script>
import CommonDrawer from '@/components/commons/drawer.vue'
import DrawerSettingsContent from '@/components/modules/portfolio/drawerSettingsContent.vue'

export default {
  name: 'settingsButton',
  components: { DrawerSettingsContent, CommonDrawer },
  data() {
    return {
      showDrawer: false,
    }
  },
  methods: {
    openDrawer() {
      this.showDrawer = true
    },
    closeDrawer() {
      this.showDrawer = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

$border-color: rgba(255, 255, 255, 0.3);

.button {
  border: 1px solid #2667FF;
  border-radius: 8px;
  padding: 5px 10px;
  background: #2667FF;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
