<template>
  <button class="button" @click="openImportModal">
    <img
      :src="require('@/assets/svg/v2/portfolio/import.svg')"
      alt="Import icon"
    />
    {{ $t('portfolio.import') }}
  </button>
  <CommonModal
    v-if="showImportModal"
    size="xxl"
    :modal-title="$t('portfolio.import_title')"
    @close="closeImportModal"
  >
    <ImportModalContent
      @refresh="$emit('refresh')"
      @close="showImportModal = false"
    />
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/commons/modal.vue'
import ImportModalContent from '@/components/modules/portfolio/importModalContent.vue'

export default {
  name: 'importButton',
  components: { CommonModal, ImportModalContent },
  data() {
    return {
      showImportModal: false,
    }
  },
  methods: {
    openImportModal() {
      this.showImportModal = true
    },
    closeImportModal() {
      this.showImportModal = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

$border-color: rgba(255, 255, 255, 0.3);

.button {
  border: 1px solid #2667FF;
  border-radius: 8px;
  padding: 5px 10px;
  background: #2667FF;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
}
</style>
